import Helmet from "react-helmet"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import RSVPConfig from "RSVPConfig"
import Card from "components/shared/Card"
import ErrorToast from "components/shared/ErrorToast"
import Nav from "components/shared/Nav"
import { useCheckUserSession } from "./hooks"
import parkingIcon from "./assets/parking-icon.svg"
import codeRedeemIcon from "./assets/code-redeem.svg"
import enterCodeIcon from "./assets/enter-code.svg"
import reserveSpotIcon from "./assets/reserve-spot.svg"
import manageParkingIcon from "./assets/settings-gear.svg"
import forwardArrow from "./assets/forward-arrow.svg"

import styles from "./Home.module.scss"

const Home = () => {
  useCheckUserSession()
  const [t] = useTranslation()
  const { features } = RSVPConfig

  const renderCard = (icon, text, link) => {
    return (
      <div className={styles.cardWrapper}>
        <Link to={link} className="text-decoration-none">
          <Card>
            <div className={styles.cardBody}>
              <div className={styles.cardHeader}>
                <img src={icon} alt="card" />
                <div className={styles.headerText}>{text}</div>
              </div>
              <img
                src={forwardArrow}
                alt="arrow"
                className={styles.arrowIcon}
              />
            </div>
          </Card>
        </Link>
      </div>
    )
  }

  return (
    <>
      <Helmet title="Welcome" />
      <div className={styles.container}>
        <Nav className={styles.homeNav} hideBackButton />
        <div className={styles.headline}>
          <img src={parkingIcon} alt="parking" />
          <div className={styles.text}>{t("home.title")}</div>
        </div>
        <div className="container-sm">
          <div className="row justify-content-center">
            <div className="col-md-8 col-xl-6">
              <div className={classNames(styles.cardsContainer)}>
                {renderCard(
                  reserveSpotIcon,
                  "Reserve a Parking Spot",
                  "/select-parking"
                )}
                {features.accessCodes && (
                  <>
                    {renderCard(enterCodeIcon, "Reserve with New Parking Code", "/code")}
                    {renderCard(
                      codeRedeemIcon,
                      "Reserve with Existing Parking Code",
                      "/parking-codes"
                    )}
                  </>
                )}
                {renderCard(
                  manageParkingIcon,
                  "Manage Your Parking",
                  "/parking-reservations"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorToast />
    </>
  )
}

export default Home
