const config = {
  name: "port-seattle",
  features: {
    accessCodes: false,
    moreInformation: true,
    hasParkingInstructions: true,
    hasPromoCodeWarning: false,
    displayPromoTotalUses: false, // Display "Redemptions left" on promo code card
    userPhoneNumber: true,
    userName: true
  },
  parkingSession: {
    // 7 days before the start time (7:00 AM)
    cancellationDeltaMs: 604800000,
    // Start time offset from midnight, i.e. 8 am
    startOffsetHrs: 7,
    // Start date offset from today (0 = today)
    startOffsetDate: 0,
    // End date offset from start time
    endOffsetDate: 300
  },
  signUp: {
    firstNameRequired: true,
    lastNameRequired: true
  }
}
export default config
