import alta from "./alta"
import crystal from "./crystal"
import parkCity from "./park-city"
import parkCityGarage from "./park-city-garage"
import heavenly from "./heavenly"
import northstar from "./northstar"
import palisades from "./palisades"
import palisadesVillage from "./palisades-village"
import solitude from "./solitude"
import brighton from "./brighton"
import northstarPreferred from "./northstar-preferred"
import brightonTown from "./brighton-town"
import dillon from "./dillon"
import honkdemo from "./honkdemo"
import oakview from "./oakview"
import stevensPass from "./stevens-pass"
import kirkwood from "./kirkwood"
import palisadesMulti from "./palisades-multi"
import northStarMulti from "./northstar-multi"
import breck from "./breck"
import ohsu from "./ohsu"
import aBasin from "./a-basin"
import manorVail from "./manor-vail"
import buildTest from "./build-test"
import ohsuGuest from "./ohsu-guest"
import ohsuVip from "./ohsu-vip"
import portSeattle from "./port-seattle"

const config = {
  alta,
  crystal,
  "park-city": parkCity,
  "park-city-garage": parkCityGarage,
  heavenly,
  northstar,
  "northstar-preferred": northstarPreferred,
  palisades,
  "palisades-village": palisadesVillage,
  solitude,
  brighton,
  "brighton-town": brightonTown,
  dillon,
  honkdemo,
  oakview,
  "stevens-pass": stevensPass,
  kirkwood,
  "palisades-multi": palisadesMulti,
  "northstar-multi": northStarMulti,
  breck,
  ohsu,
  "a-basin": aBasin,
  "manor-vail": manorVail,
  "build-test": buildTest,
  "ohsu-guest": ohsuGuest,
  "ohsu-vip": ohsuVip,
  "port-seattle": portSeattle
}
const WHITELABEL = process.env.REACT_APP_WHITELABEL
export default config[WHITELABEL]
